import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { decrementTime, selectTimeLeft } from '../slices/timerSlice';
import { Box, CircularProgress } from '@mui/material';

const Timer = () => {
    const dispatch = useDispatch();
    const timeLeft = useSelector(selectTimeLeft);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            dispatch(decrementTime());
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [dispatch]);

    const progressTimer = (progress) => {
        const TotalpercentTime = progress / 3;
        return TotalpercentTime;
    }

    return (
        <>
        <Box size="44vw" className="childTimer" >
        <CircularProgress variant="determinate"  color='secondary' value={progressTimer(timeLeft) } size="44vw"></CircularProgress>
        {/* <Button onClick={() => dispatch(resetTimer())}>
                Reset Timer
        </Button> */}
        </Box>
       
        {/* <Box textAlign="center" mt={4}>
            <Typography>{progressTimer(timeLeft)}</Typography>
            <Typography variant="h6" mt={2}>{timeoutMessage || formatTime(timeLeft)}</Typography>
            <Button variant="contained" color="primary" onClick={() => dispatch(resetTimer())}>
                Reset Timer
            </Button>
        </Box> */}
        </>
    );
};

export default Timer;
