import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Grid, Typography, Button, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../slices/donorinfoSlice';
import { nextComponent } from '../slices/navigatorSlice';
import { postescanadaService } from '../service/postCanadaService';
import StripeExpressCheckoutWrapper from './StripeExpressCheckoutWrapper';
//import ExpressCheckout from './ExpressCheckout';

const DonorInfoForm = ({ onFormSubmit }) => {
    const { handleSubmit, control, reset, formState: { errors }, setValue, getValues } = useForm();
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.form);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [addressLine1, setAddressLine1] = useState([]);
    useEffect(() => {
        // Load form data from Redux store
        reset(formData);
    }, [formData, reset]);

    const onSubmit = (data) => {
        console.log(data);
        // Save form data to Redux store
        dispatch(setFormData(data));
        dispatch(nextComponent());
        // onFormSubmit();  // Call the handler passed from the parent component
    };

    const handleAddressChange = async (event, value) => {
        if (value && value.length >= 1) {  // Fetch suggestions only if input length is greater than or equal to 3
            try {
                console.log('Fetching address suggestions:');
                const results = await postescanadaService.findByKeyword(value);
                console.log('Fetched address suggestions:'+JSON.stringify(results));                
                setAddressSuggestions(results || []);  // Ensure results is an array
            } catch (error) {
                console.error('Error fetching address suggestions:', error);
                setAddressSuggestions([]);  // Clear suggestions in case of error
            }
        } else {
            setAddressSuggestions([]); 
            setValue('address','');              
            setValue('city', '');
            setValue('province', '');
            setValue('pincode','');
        }
    };

    const handleAddressSelect = (event, value) => {
        if (value && value.Description) {
            const { Description, Text } = value;
            setValue('address', Text);

            if (Description && Description.split(',').length === 3) {
                const addressDetails = Description.split(',');
                setValue('city', addressDetails[0].trim());
                setValue('province', addressDetails[1].trim());
                setValue('pincode', addressDetails[2].trim());
            }
        } else {
            setValue('address', value || '');            
        }
    };
    const getValidationRules = () => {  
        console.log('Address line 1 length:'+addressLine1.length);
        console.log('Address line 1 value:'+addressLine1.value);       
          if(addressLine1.length ===0){
            return { required: 'Address Line 1 is required' };
          }
          else{
            return {};
          }
        // Example condition: You can replace this with your actual conditionconst condition = true; // Replace with your actual conditionif (condition) {             
    };
    return (
        <>
        <div className='checkout-option-header'>
            <Typography  variant='formTitle' sx={{fontSize: { lg: "3.5vw",md: "3.5vw",sm: "3.5vw", xs: "3.5vw"},  p: 2}} component="strong" gutterTop gutterBottom>Donate with</Typography>
        </div>
        <div className="express-checkout">
            <StripeExpressCheckoutWrapper/>
        </div>
        <div className='checkout-option-text'>
            <Typography  variant='formTitle' sx={{fontSize: { lg: "3vw",md: "3vw",sm: "3vw", xs: "3vw"},  p: 2}} component="strong" gutterTop gutterBottom>OR</Typography>
        </div>
        <Box sx={{ p: 0 }}>
            <Typography variant='formTitle' sx={{fontSize: { lg: "3vw",md: "3vw",sm: "3vw", xs: "3vw"},  p: 2}} component="p"  gutterBottom>
               Enter your information
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'First Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.firstName}
                                    helperText={errors.firstName ? errors.firstName.message : ''}
                                    onChange={(e) => {
                                        setValue('firstName', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Last Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.lastName}
                                    helperText={errors.lastName ? errors.lastName.message : ''}
                                    onChange={(e) => {
                                        setValue('lastName', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email Address is required',
                                pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                    message: 'Email Address is not valid',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Email Address"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.email}
                                    helperText={errors.email ? errors.email.message : ''}
                                    onChange={(e) => {
                                        setValue('email', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="address"
                            control={control}
                            defaultValue=""
                            rules={getValidationRules()}
                            
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    freeSolo                                    
                                    options={addressSuggestions}
                                    getOptionLabel={(option) => option.Text || option}
                                    onInputChange={handleAddressChange}
                                    onChange={handleAddressSelect}
                                   
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Address Line 1"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            error={!!errors.address}
                                            helperText={errors.address ? errors.address.message : ''}
                                            onBlur={() => setValue('address', getValues('address'))}
                                           
                                            onChange={(e) => {                                                {                                                    
                                                    //setAddressLine1(e.target.value)}                                           
                                                    setValue('address', e.target.value);
                                                    //field.onChange(e);
                                                }
                                            }
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="province"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Province is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Province"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.province}
                                    helperText={errors.province ? errors.province.message : ''}
                                    onChange={(e) => {
                                        setValue('province', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'City is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.city}
                                    helperText={errors.city ? errors.city.message : ''}
                                    onChange={(e) => {
                                        setValue('city', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="pincode"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Postal Code is required' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Postal Code"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    error={!!errors.pincode}
                                    helperText={errors.pincode ? errors.pincode.message : ''}
                                    onChange={(e) => {
                                        setValue('pincode', e.target.value);
                                        field.onChange(e);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" sx={{ marginTop: 2 }} fullWidth>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
        </>
    );
};

export default DonorInfoForm;
