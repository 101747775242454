import React from 'react';
import { Button } from '@mui/material';
import DonorInfoForm from '../components/DonorInfoForm';
import SponsorNow from '../components/SponsorNow';
import { useDispatch, useSelector } from 'react-redux';
import { nextComponent } from '../slices/navigatorSlice';
import StripeCheckoutWrapper from './StripeCheckoutWrapper';

const Footer = () => {
    const dispatch = useDispatch();
    const currentIndex = useSelector((state) => state.navigator.currentIndex);
    const components = [<SponsorNow />, <DonorInfoForm/>, <StripeCheckoutWrapper />];
    const handleNext = () => {
        dispatch(nextComponent());
    };

    return (
        
        <footer>
            {components[currentIndex]}
               
            {currentIndex < components.length - 1 && currentIndex !== 1 && <Button variant="contained" color="primary" type="button" onClick={handleNext} fullWidth>
                Sponsor Now
            </Button>}
            {currentIndex < components.length - 1 && currentIndex !==1 &&  <div className="disclaimer">
            <p>In this fiscal year, World Vision Canada anticipates raising $405.5 million in total revenue for its community development, emergency relief and advocacy work, of which approximately 13.2% will be used for necessary fundraising. In cases where donations exceed what is needed or where local conditions prevent program implementation, World Vision Canada will redirect funds to similar activities to help people in need. World Vision Canada is federally incorporated and located in Mississauga, Ontario. For more information, or for a copy of our latest audited financial statements, please visit our website at <a href="https://www.worldvision.ca/">www.worldvision.ca</a>. You may also send an email to <a href='mailto:info@worldvision.ca'>info@worldvision.ca</a> or contact the Director, Operational Excellence at <a href='tel:18002684888'>1.800.268.4888</a>. </p>
            </div>}
        </footer>
    );
}

export default Footer;