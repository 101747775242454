// src/formSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = 
{
  "birthday": "",
  "childId": "",
  "childNumber": "",
  "countryCode": "",
  "createdDate": "",
  "firstName": "",
  "genderCode": "",
  "hasVideo": "",
  "imageLatestUrl": "",
  "imageUrl": "",
  "lastName": "",
  "lockExpiresAt": "",
  "lockedBy": "",
  "name": "",
  "pfLangauge": "",
  "projectId": "",
  "published": "",
  "reservationExpiryDate": "",
  "url": "",
  "videoUrl": "",
  "age": "",
  "country": "",
  "countryWithArticle": "",
  "gender": "",
  "NationalOfficeAddress": "",
  "childIdentificationNumber": "",
  "nationalOfficeAddress": "",
  "projectName": "",
  "projectUrl": "",
  "packageId": ""
};

const childSlice = createSlice({
  name: 'child',
  initialState,
  reducers: {
        setChildData: (state, action) => {
        return { ...state, ...action.payload };
        },
        resetChildData: () => initialState,
    },
});

export const { setChildData, resetChildData } = childSlice.actions;
export default childSlice.reducer;
