import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0C6699',
        },
        secondary: {
            main: '#F36C21',
        },
    },

    typography: {
        fontSize: 20,
        fontFamily: [
          'Lato',
          'Helvetica',
          'Arial'
        ].join(','),
        intro: {
          fontWeight: 600,
          textAlign: "center",
        },
        childName: {
          fontWeight: 700
        },
        childAge: {
          fontWeight: 600,
          color: "#E7600C"
        },
        childCountry: {
          fontWeight: 600,
          color: "#666666"
        },
        formTitle: {
          textAlign: "center",
        },
        sponsorNowtext: {
          fontWeight: 600
        }

        
    }
});

export default theme;
