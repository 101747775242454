import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timeLeft: 300, // 5 minutes in seconds
    timeoutMessage: '',
};

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        decrementTime(state) {
            if (state.timeLeft > 0) {
                state.timeLeft -= 1;
            } else {
                state.timeoutMessage = 'Time out!';
            }
        },
        resetTimer(state) {
            state.timeLeft = 300;
            state.timeoutMessage = '';
        },
    },
});

export const { decrementTime, resetTimer } = timerSlice.actions;

export const selectTimeLeft = state => state.timer.timeLeft;
export const selectTimeoutMessage = state => state.timer.timeoutMessage;

export default timerSlice.reducer;
