import { getData } from "./SmileService";
import { SECRET_KEYS } from "../constants/constants";

const canadaPostRootUrl = process.env.REACT_APP_POSTCANADA_API_URL;
const apiFindUrl =
  canadaPostRootUrl + '/Find/v2.10/json3ex.ws?Country=CAN&LanguagePreference=en&LastId=&SearchFor=Everything&OrderBy=UserLocation&$block=true&MaxSuggestions=7&$cache=true&Key=$API_KEY&SearchTerm=$KEYWORD';

async function findByKeyword(keyword) {
  const apiKey = await getData("",`/api/get-secret/${SECRET_KEYS.WVC_POST_CAN_API_KEY_SECRET_NAME}`);
  console.log('Papi key from key vault:', apiKey.value);
  const searchUrl = apiFindUrl.replace('$API_KEY', apiKey.value).replace('$KEYWORD', keyword)
  console.log('Post code Search URL:', searchUrl);
  const data = await fetch(searchUrl)
    .then(d => d.json())
    .then(d => d.Items.filter(i => i.Next === 'Retrieve'))
  console.log('Post code Data:', data);
  return data
};
export const postescanadaService = { findByKeyword }