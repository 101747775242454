import React from "react";
import WVCLogo from "../../Assets/wvc-logo.png"
const Header = () => {
    return (
        <header>
                <a href="/"><img src={WVCLogo} alt="World Vision logo"/> </a>
        </header>
    );
}
 
export default Header;