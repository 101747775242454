import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
//import ExpressCheckout from './ExpressCheckout';
//import ExpressCheckoutNew from './ExpressCheckoutNew';
import ExpressCheckout from './ExpressCheckout';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const options = {
  mode: 'subscription',
  amount: 4900,
  currency: 'cad',
  setup_future_usage: 'off_session',
  // Fully customizable with appearance API.
  appearance: {/*...*/ },
};
const StripeExpressCheckoutWrapper = () => {
  return (
    <Elements stripe={stripePromise} options={options}>
        {/* <ExpressCheckoutNew/> */}
        <ExpressCheckout/>
    </Elements>
  )
}

export default StripeExpressCheckoutWrapper;
