import { Box, Grid, Typography} from '@mui/material';
import React from 'react'

const SponsorNow = () => {
  return (
    <Box sx={{ paddingBottom: 2 }}>
      <Grid container spacing={1} sx={{ alignItems: 'flex-end' }}>
          <Grid item xs={6}>
            <Typography variant='sponsorNowtext' sx={{fontSize: { lg: "3.4vw",md: "3.4vw",sm: "3.4vw", xs: "3.4vw"}}}>Monthly Giving</Typography>
          </Grid>
          <Grid item xs={6}  textAlign={'right'}>
            <Typography variant='sponsorNowtext' sx={{fontSize: { lg: "7vw",md: "6vw",sm: "7vw", xs: "8vw"}, }} color={'secondary'}>$49</Typography>
          </Grid>
      </Grid>
    </Box>
  )
}

export default SponsorNow;