import { Typography } from '@mui/material';
import AvatarImage from "../../Assets/avatar.png";
import Timer from './Timer';
import { useSelector } from 'react-redux';

const StaticText = () => {
    const childData = useSelector((state) => state.child);
    return (
        <div className="childSponsor">
        <div className="childAvatar">
            <div className="childPhoto">
                <img src={childData.imageLatestUrl? childData.imageLatestUrl : AvatarImage} alt="" />
            </div>
            <Timer></Timer>
        </div>
        <div className="childIntroText">
            <Typography variant="intro" sx={{fontSize: { lg: "3.4vw",md: "3.4vw",sm: "3.4vw", xs: "3.4vw"}}} marginBottom={2}>Today is a BIG day for</Typography>
            <Typography variant="childName" sx={{fontSize: { lg: "9vw",md: "7vw",sm: "6vw", xs: "6vw"}}}>
                { childData.firstName }
            </Typography>
            <Typography variant="childAge" sx={{fontSize: {  lg: "3.8vw",md: "3.8vw",sm: "3.8vw", xs: "3.8vw"}}}>{ childData.age  + " Years Old" }</Typography>
            <Typography variant="childCountry" sx={{fontSize: { lg: "3.8vw",md: "3.8vw",sm: "3.8vw", xs: "3.8vw"}}}>{ childData.country }</Typography>
        </div>
    </div>
    );
};

export default StaticText;
