// src/formSlice.js
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    pincode: '',
    province: '',
    city:''
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
        setFormData: (state, action) => {
        return { ...state, ...action.payload };
        },
        resetFormData: () => initialState,
    },
});

export const { setFormData, resetFormData } = formSlice.actions;
export default formSlice.reducer;
