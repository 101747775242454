import axios from 'axios';

// Function to create an axios instance
const createAxiosInstance = (baseURL, subscriptionKey, bearerToken) => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': subscriptionKey || '',
      'Authorization': bearerToken ? 'Bearer ' + bearerToken : ''
    },
  });
};

// GET method
export const getData = async (baseURL, endpoint, contentType, subscriptionKey = '', bearerToken = '') => {
  let axiosInstance = createAxiosInstance(baseURL, subscriptionKey, bearerToken);
  try {
    const response = await axiosInstance.get(endpoint, {
      headers: { 'Content-Type': contentType }
    });
    console.log('The json response from API:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// POST method
export const postData = async (baseURL, endpoint, data, subscriptionKey = '', bearerToken = '') => {
  let axiosInstance = createAxiosInstance(baseURL, subscriptionKey, bearerToken);
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
