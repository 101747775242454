import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader = ({ height }) => {
  return (
    <div class="loader-container" style={{ height: height }}>
      <div class="spinner">
        <CircularProgress color="secondary" size="30%" />
      </div>
    </div>
  );
};

export default PageLoader;
