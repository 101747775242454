import React from "react";
import Header from "../components/Header";
import ChildLockText from "../components/ChildLockText";

const AlreadySponsored = () => {
  return (
    <>
      <Header />
      <ChildLockText />
    </>
  );
};

export default AlreadySponsored;
