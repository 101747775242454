import { configureStore } from '@reduxjs/toolkit';
import timerReducer from '../slices/timerSlice';
import formReducer from '../slices/donorinfoSlice';
import navigatorReducer from '../slices/navigatorSlice';
import childReducer from '../slices/childinfoSlice';

export const store = configureStore({
    reducer: {
        timer: timerReducer,
        form: formReducer,
        navigator: navigatorReducer,
        child: childReducer
    },
});
