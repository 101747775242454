import React from "react";
import Header from "../components/Header";
import ThankYouText from "../components/ThankYouText";
import FooterBlank from "../components/FooterBlank";

const ThankYou = () => {
  return (
    <>
      <Header />
      {/* <ThankYouBanner name="Gerald" /> */}
      <ThankYouText />
      <FooterBlank />
    </>
  );
};

export default ThankYou;
