import { createSlice } from '@reduxjs/toolkit';

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState: {
    currentIndex: 0,
  },
  reducers: {
    nextComponent: (state) => {
      if (state.currentIndex < 2) {
        state.currentIndex += 1;
      }
      if (state.currentIndex !== 0){
        document.body.classList.add('sponsoring');
      }
      else{
        document.body.classList.remove('sponsoring');
      }
    },
    previousComponent: (state) => {
      if (state.currentIndex > 0) {
        state.currentIndex -= 1;
      }
      if (state.currentIndex !== 0){
        document.body.classList.add('sponsoring');
      }
      else{
        document.body.classList.remove('sponsoring');
      }
    },
  },
});

export const { nextComponent, previousComponent } = navigatorSlice.actions;

export default navigatorSlice.reducer;
