import React from "react";
import { Typography } from "@mui/material";
import AvatarImage from "../../Assets/avatar.png";
import { useSelector } from "react-redux";

const ThankYouText = () => {
  const childData = useSelector((state) => state.child);
  const responsiveFontSizesBody = {
    lg: "4vw",
    md: "4vw",
    sm: "4vw",
    xs: "4vw",
  };
  const responsiveFontSizesHeading = {
    lg: "9vw",
    md: "7vw",
    sm: "6vw",
    xs: "6vw",
  };
  return (
    <>
      <div className="thankYouBanner">
        <Typography
          align="center"
          variant="body1"
          sx={{ fontSize: responsiveFontSizesBody }}
        >
          You've brightened
        </Typography>
        <Typography
          align="center"
          variant="h6"
          sx={{ fontSize: responsiveFontSizesHeading }}
        >
          {childData.firstName}'s day
        </Typography>
      </div>
      <div className="childThankYou">
        <div className="childAvatarThankYou">
          <div className="childPhotoThankYou">
            <img
              src={
                childData.imageLatestUrl
                  ? childData.imageLatestUrl
                  : AvatarImage
              }
              alt=""
            />
          </div>
        </div>
        <div className="thankYouText">
           <Typography
            align="center"
            variant="body1"
            sx={{ fontSize: responsiveFontSizesBody }}
          >
            Confirmation #{childData.childId}
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontSize: responsiveFontSizesHeading }}
          >
            Thank you<br></br>for your generosity!
          </Typography>
          <Typography
            align="center"
            variant="body1"
            sx={{ fontSize: responsiveFontSizesBody }}
          >
            You're going to make a powerful difference in the life of{" "}
            {childData.firstName}. Keep an eye on
            your email for more ways to connect with your new sponsored child.
          </Typography>
        </div>
      </div>
    </>
  );
};

export default ThankYouText;
