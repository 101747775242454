import React, { useEffect, useState } from "react";
import HeroBanner from "../components/HeroBanner";
import StaticText from "../components/StaticText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { setChildData } from "../slices/childinfoSlice";
import { getData } from "../service/SmileService";
import { useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../components/PageLoader";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  useEffect(() => {
    async function getChildData() {
      try {
        const queryValue = query.get("query");
        if (queryValue) {
          console.log("Query string parameter:", queryValue);
          let res = await getData(process.env.REACT_APP_SPONSORSHIP_API_URL,
            "/reserveQRChild?query=" + queryValue,
            "application/xml"
          ).then((data) => {
            dispatch(setChildData(data));
            console.log("data fetched for child: " + JSON.stringify(data));
          });
          console.log("data fetched for child api: " + JSON.stringify(res));
          
        } else {
          navigate("/sponsored");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          navigate("/sponsored");
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    }
    getChildData();
  }, [dispatch, navigate, query, loading]);
  return (
    <>
      <Header />
      <HeroBanner />
      <StaticText />
      <Footer />
      {loading ? (
        <>
          <PageLoader height="100%" />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
