import React from 'react';
import { Button  } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import {  previousComponent } from '../slices/navigatorSlice';

const HeroBanner = () => {
    const dispatch = useDispatch();
    const currentIndex = useSelector((state) => state.navigator.currentIndex);


    const handleBack = () => {

        dispatch(previousComponent());
    };
    return (
        <div className="banner">
             {currentIndex > 0 && <Button style={{ fontSize: '3vw' }}  className='btnBack' variant="contained"  type="button" startIcon={<ArrowBackIosIcon style={{ fontSize: '3vw',  marginRight: 0 }} />} onClick={handleBack}>Back</Button>}
        </div>
    );
};

export default HeroBanner
